import React from 'react'
import Sv from '../../Comp/img/new.jpeg' 


const Investor = () => {
  return (
  <>
     

  <section>
   <div className='container  mt-5 '>
    <div className='row'>
     <div className='col-lg-12 col-sm-12'>
     <h1 className='text-center text-white investment' > What is   <span className='Humanity text-rainbow-animation '>Cryptocurrency</span>  ?</h1>
     </div>
    </div>



     <div className='row'>
     <div className='col-lg-11   m-auto'>
          
     <div className='row my-5'> 
      <div className='col-lg-6 my-auto col-md-6 col-sm-12 2 order-last order-md-second' >
        
      <p className='pt-5 mt-5 text-white  Investing' style={{fontWeight:'300',fontSize:'25px'}} >
Cryptocurrency is digital currency on decentralized<span className='Humanity6  '> blockchains </span>like Bitcoin, Ethereum, and Ripple. Using cryptography and distributed ledgers, it enables secure, transparent, and peer-to-peer transactions, reducing the need for intermediaries. It fosters financial inclusion and innovation in decentralized finance (DeFi). </p>

      </div>

      <div className='col-lg-6 col-md-6 col-sm-12 mt-4 order-first order-md-last'>
        <div className='Sv' style={{paddingTop:'80px'}}>
        <img src={Sv} alt='img' className='p-1' style={{width:'100%',height:'50vh'}}/> 
      </div>
      </div>
     </div>
   </div>
 </div>
</div>
  </section>
  </>
  )
}

export default Investor