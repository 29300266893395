import React from 'react'
import Sv from '../../Comp/img/image-2.jpg' 
const Metaverse = () => {
  return (
    <>
        <section>
   <div className='container  mt-5 '>
    <div className='row'>
     <div className='col-lg-12 col-sm-12'>
     <h1 className='text-center text-white investment' >  <span className='Humanity text-rainbow-animation '>METAVERSE</span>  NFT</h1>
     </div>
    </div>



     <div className='row'>
     <div className='col-lg-11   m-auto'>
          
     <div className='row my-5'> 
     <div className='col-lg-6 col-md-6 col-sm-12 mt-4 order-first order-md-last'>
     <div className='Sv' style={{paddingTop:'80px'}}>
     <img src={Sv} alt='img' className='p-1' style={{width:'100%'}}/> 
   </div>
   </div>
      <div className='col-lg-6 my-auto col-md-6 col-sm-12 2 order-last order-md-second' >
        
      <p className='pt-5 mt-5 text-white  Investing' style={{fontWeight:'100',fontSize:'20px'}} >

      The metaverse NFT space merges virtual reality, digital assets, and blockchain. Users own, trade, and interact with unique<span className='Humanity6  '>  NFTs </span>representing digital assets like virtual real estate and art. Stored on blockchains, NFTs provide provenance and authenticity, offering immersive experiences with ownership features for creators and collectors in the virtual ecosystem. </p>

      </div>

     
     </div>
   </div>
 </div>
</div>
  </section>
    </>
  )
}

export default Metaverse
