import React from 'react'
import { BsArrowRightShort} from 'react-icons/bs'
import Header from '../Header/Header';
import side from '../../Comp/img/mtfx-single-1.png'
import { NavLink, useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer';

const WhitePaper = () => {

  const Location = useLocation();
  return (
    <>
       <div>
        <Header/>
       </div>
       
       
       <div className='container' style={{paddingTop:'80px'}}>
        <div className='row'>

 <div className='col-lg-11 m-auto'>
  <div className='row'>


  <div className='col-lg-6 col-md-6 col-sm-12'>
          
  <h1 className='hed3 text-white Humanity text-rainbow-animation' style={{paddingTop:'80px', fontSize:'50px'}}>MTFX....</h1>


  <p className='mt-4 hed4' style={{fontSize:'22px', color:'#fff', fontWeight:'400'}}>
  MTFX (MX) is a BEP20 token functioning on the Binance Smart Chain (BSC), featuring <br/>a steadfast supply of 11,000,000 MX. With 18 decimals, MTFX ensures precision in its <br/> <span className='Humanity text-rainbow-animation'> transactions. The token leverages BSC Scan for transaction verification, ensuring  </span>transparency and accountability. Its smart contract is intricately designed and  <br/>deployed on the Binance Smart Chain, ensuring seamless integration within the broader BSC ecosystem. </p>
   
   

  <NavLink to='https://shield-ai.gitbook.io/armour-ai-whitepaper/our-mission-and-vision/join-the-revolution' style={{textDecoration:'none'}}>
  <div className='Whitepaper'>
  <button type='btn' className='mt-4' style={{backgroundColor:'transparent',color:'#fff',   border:'1px solid #fff', fontSize:'20px', borderRadius:'30px', padding:'5px 5px 5px 45px'}}>View Whitepaper <BsArrowRightShort className='ms-3 p-1'
  style={{background: 'linear-gradient(146.53deg, #2469ee, 2.81%,   #04728c, 44.51%, #5f23e2,  82.08%, #04748c 123.02%)', borderRadius:'50%', fontSize:'50px'}}/></button>
  </div>     
  
  </NavLink>


 </div>


 <div className='col-lg-6 col-md-6 col-sm-12'>
    <div className='bag'>
    <img src={side} alt='#' className='ass' style={{width:'100%', zIndex:'-999'}}/>
    </div> 
</div> 

  </div>
 </div>

       
        </div>
       </div>

       
       <div className='mt-5' style={{display:Location.pathname=="/"?"none":""}}>
       <Footer/> 
       </div>

      
    </>
  )
}

export default WhitePaper;