import React, { useState } from 'react'
import { BsFillSendFill } from 'react-icons/bs'
import { BsDiscord } from 'react-icons/bs'
import { AiOutlineTwitter } from 'react-icons/ai'
import { FiYoutube } from 'react-icons/fi'
import { FaLinkedinIn } from 'react-icons/fa'
import { FaTelegramPlane } from 'react-icons/fa';
import { MdCopyright } from 'react-icons/md'
import { AiOutlineReddit } from 'react-icons/ai'
import { AiOutlineMedium } from 'react-icons/ai'
import Logo from '../../Comp/img/logo (5).png'
import { NavLink } from 'react-router-dom'
import Mtfx from '../../Comp/img/mtfx-logo.png'
import '../../Comp/Header/Header2.css'

const Footer = () => {

  const [Subscribe, setSubscribe] = useState("");
  const [Click, setClick] = useState("");

  const handledCheck = () => {

    if (Subscribe.length >= 1) {
      setClick("click");
    }
    else {
      setClick("notClick");
    }


  }

  return (
    <>
      <section style={{  }} className='background'>
        <div className='container '>
          <div className='row py-5'>

            <div className='col-lg-3 col-md-6 col-sm-12'>

              <img src={Mtfx} alt='img' style={{ width: '70%', marginTop: '-9px' }} />

              <h5 className='text-white mt-4' style={{ lineHeight: '30px' }}>MTFX<br />
                Revolutionizing<br /> <span className='Subscribe1'> Blockchain </span>technology<br /> with advanced <span className='Subscribe1'></span> Safety.
              </h5>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-12'>

              <h5 className='text-white text-left Utility ps-3' style={{ cursor: 'pointer' }}> Ouick Links</h5>

              <ul className='unorder ps-3' style={{ listStyle: 'none', color: '#fff', lineHeight: '40px', textAlign: 'left', paddingLeft: '0%' }}>


                <NavLink to='/Roadmap' style={{ textDecoration: 'none', color: '#fff' }}>
                  <li>Roadmap</li>
                </NavLink>
  {/*<NavLink to='/Career' style={{ textDecoration: 'none', color: '#fff' }}><li>Career</li></NavLink>*/}
                <NavLink to='/WhitePaper' style={{ textDecoration: 'none', color: '#fff' }}><li>WhitePaper</li></NavLink>
                <NavLink to='/TokenSystem' style={{ textDecoration: 'none', color: '#fff' }}> <li>TokenSystem</li> </NavLink>
  {/*<NavLink to='/SportLight' style={{ textDecoration: 'none', color: '#fff' }}><li>  Spot Light on Ai  </li></NavLink>*/}
                {/* <NavLink to='/Sdk' style={{textDecoration:'none',color:'#fff'}}><li>Product</li></NavLink>  */}
              </ul>

            </div>

            <div className='col-lg-3 col-md-6 col-sm-12'>

              <h5 className='text-white  Utility ps-0' style={{ cursor: 'pointer' }}>Utility page</h5>
              <ul className='unorder ps-0' style={{ listStyle: 'none', color: '#fff', textAlign: 'left', lineHeight: '40px', paddingLeft: '0%' }}>
  {/*<NavLink to='/Guide' style={{ textDecoration: 'none', color: '#fff' }}><li>Brand Guide</li></NavLink>*/}
                {/*<NavLink to='/Agreement' style={{ textDecoration: 'none', color: '#fff' }}> <li>Agreement for Sale Of Tokens</li></NavLink>*/}
                <NavLink to='/PrivacyPolicy' style={{ textDecoration: 'none', color: '#fff' }}> <li>Privacy Policy</li> </NavLink>
              </ul>

            </div>

            <div className='col-lg-3 col-md-6 col-sm-12'>
              <h5 className='text-white Utility ps-3' style={{ cursor: 'pointer' }}>Location</h5>
              {/*<h5 className=' text-white Utility  mt-3 ps-3' style={{ cursor: 'pointer' }}>Developer Tools</h5>*/}

              <ul className='unorder ps-3' style={{ listStyle: 'none', color: '#fff', textAlign: 'left', lineHeight: '40px', paddingLeft: '0%' }}>

                {/*<NavLink to="/Sdk" style={{ textDecoration: 'none', color: '#fff' }}><li> S D K</li></NavLink>*/}
                <NavLink to='' style={{ textDecoration: 'none', color: '#fff' }}> Street:- Al Jawhara building, Khalid Bin Waleed    Bur  Dubai<li>
</li></NavLink>
  <NavLink to='' style={{ textDecoration: 'none', color: '#fff' }}>State:- Emirates  Abu  Dhabi
<li>
</li></NavLink>
 <NavLink to='' style={{ textDecoration: 'none', color: '#fff' }}>Zip code:-11101
<li>
</li></NavLink>
<NavLink to='' style={{ textDecoration: 'none', color: '#fff' }}>Phone number:-+971   971 2 6775744 <li>
</li></NavLink>

  {/*<NavLink to='' style={{ textDecoration: 'none', color: '#fff' }}> <h5 > <span className='Utility'> AI Tools </span> </h5> </NavLink>*/}
  {/*<NavLink to='/Chat' style={{ textDecoration: 'none', color: '#fff' }}> <li>  Safe Ai  </li>
                </NavLink>*/}
               {/*<NavLink to='/Generate' style={{ textDecoration: 'none', color: '#fff' }}><li> Armor IG</li></NavLink>*/}

              </ul>
            </div>

          </div>




        </div>



        <div className='container-fluid  ' >
          <div className='row' style={{ backgroundColor: '#030614' }}>
            <div className='col-lg-12 col-sm-12'>

              <p className='text-white text-center pt-3'  >
                <span className=' Subscribe1'  ></span>  </p>


            </div>
          </div>
        </div>


        <div className='container-fluid pt-1'>
          <div className='row' style={{ backgroundColor: '#030614' }}>
            <div className='col-lg-12 col-sm-12'>

              <p className='text-white text-center pt-3' style={{ fontSize: '18px', cursor: 'pointer' }}> <span className='Subscribe1' style={{ fontSize: '18px' }}>MTFX</span> <span><MdCopyright /></span> 2024 </p>


            </div>
          </div>
        </div>



      </section>

    </>
  )
}

export default Footer