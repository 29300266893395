import React from 'react'
import Header from '../Comp/Header/Header'
import Footer from '../Comp/Footer/Footer'

const PrivacyPolicy = () => {
  return (
    <>

<div>
    <Header/>
</div>

     <div className='container' style={{paddingTop:'100px'}}>
        <div className='row'>
            <div className='col-lg-9 col-sm-12 m-auto'>
                <div className='row'>
                    <div className='col-lg-12 col-sm-12'>
                            
                           <h3 className='text-center text-white my-3 ' style={{fontWeight:'600'}} > <span className='Privacy'>Privacy Policy</span></h3>
                            <p className='text-white '> 
                            This Privacy Policy outlines the practices of MTFX regarding the collection, utilization, and protection of personal information from users engaging with our website and services. We prioritize the safeguarding of your privacy and ensuring the security of your personal data.<br/> <br/> 

                        <h4 className='my-4' style={{fontWeight:'500', textAlign:'center'}}>Information We Collect</h4>
                        <h5>Personal Information </h5>
                        We may gather personal details like your name, email address, contact information, and any other data voluntarily provided when you interact with our website, sign up for services, or seek support.<br/> <br/> 
                             
                             <h5 > Usage Information  </h5>
                        
                             Non-personal data about your interactions with our website and services, such as IP address, browser type, device information, and website activity, may be collected through cookies and similar technologies.<br/> <br/> 

                      <h5>  How We Use Your Information </h5>
                      Your personal information is utilized to deliver and enhance our services, respond to inquiries, and tailor your experience. Aggregated or anonymized data may also be used for analytical purposes.<br/> <br/> 

                       <h5>Communications </h5>
                        
                       We may employ your personal information to send important updates, newsletters, marketing communications, and other pertinent information about our products and services. You can opt out of marketing communications anytime.  <br/> <br/> 

                       <h5> Legal Compliance </h5>
                        
                       Your personal information may be used and disclosed to adhere to applicable laws, regulations, legal processes, or enforceable governmental requests.<br/> <br/> 

                       <h4 className='my-4' style={{textAlign:'center', fontWeight:'500',}}>  Information Sharing and Disclosure</h4>
                        <h5>Third-Party Service Providers</h5>
                        We may share your personal information with trusted third-party service providers aiding us in service delivery, business operations, and website/service enhancement. These entities are obligated to protect your information and prohibited from using it for other purposes.<br/> <br/> 

                         <h5>  Legal Requirements </h5>
                         We may disclose your personal information if required by law or if we believe such disclosure is necessary to protect our rights, comply with legal obligations, or ensure user safety.<br/> <br/> 

                      <h5> Data Security</h5> 
                      While employing reasonable measures to protect the confidentiality and security of your personal information, we acknowledge that no internet transmission or electronic storage is entirely secure. Absolute security cannot be guaranteed.<br/> <br/> 

                        Your Rights
                        You have the right to access, update, and correct your personal information. You may also request the deletion of your personal information, subject to legal obligations. To exercise these rights or address concerns about our privacy practices, contact us using the details provided below.<br/> <br/> 

                        Changes to this Privacy Policy<br/> <br/> 
                        We reserve the right to update or modify this Privacy Policy. Changes become effective immediately upon posting the revised policy on our website. We encourage periodic review for updates.</p>
                    </div>
                </div>
            </div>
        </div>
     </div>


     <div className='mt-5'>

        <Footer/>
     </div>
    </>
  )
}

export default PrivacyPolicy