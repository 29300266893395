import React from 'react'
import Header from '../Header/Header'
import rrr from '../../Comp/img/Li.png'
import Footer from '../Footer/Footer'
import Modal from '../Modal/Modal'
import { useLocation } from 'react-router-dom'

const Career = () => {

   const Location = useLocation();

  return (
   
     <>

     <div>
     <Header/>
     </div>
        

     <div className='container' style={{paddingTop:'120px'}}>
      <div className='row'>
       <div className='col-lg-8 col-sm-12 m-auto'>
        <div className='row'>
        <div className='col-lg-12 col-sm-12'>

        <div className='row'>
         <div className='col-lg-7 col-md-7 col-sm-12 mt-3'>
         <div style={{display:'flex', justifyContent:'center'}}>
           <button className='Hiring'>We're Hiring</button>  
         </div>
         
         </div>
         
         <div className='col-lg-4 col-md-5 col-sm-12 mt-3'>
         <div>  
         <button className='Hiring2  ' data-bs-toggle="modal" data-bs-target="#exampleModal">Apply Now</button>
         </div>
         
         </div>
        </div>
         
         <div>
          <div class="accordion mt-5" id="accordionExample " >
          <div class="accordion-item mt-3" style={{borderRadius:'8px',backgroundColor:'#030614', border:'1px solid #fff',color:'#fff'}}>
            <h2 class="accordion-header" id="headingno">
              <button class="accordion-button collapsed  " type="button" data-bs-toggle="collapse" data-bs-target="#collapseno" aria-expanded="false" aria-controls="collapseno"
              style={{boxShadow:'none',borderRadius:'8px',border:'none',  backgroundColor:'#030614', fontSize:'20px' }}>
               Software Engineer (Full Stack) – API (Remote), Dubai  
              </button>
            </h2>
            <div id="collapseno" class="accordion-collapse collapse" aria-labelledby="headingno" data-bs-parent="#accordionExample">
              <div class="accordion-body">
              <p> We are looking for a self-starter engineer who loves building new products in an iterative and fast-moving environment. In this role, you will be working on building out the API platform which millions of developers use to build on top of our models – users of our API now include everyone from hobbyist developers to Fortune 500 companies. You will also collaborate closely with research teams to bring the latest cutting-edge models to our API.<br/><br/>

              <h5 ><span className='Subscribe1'> In this role, you will: </span></h5> 
               <ul>
                <li>Architect, build and deploy the products that power our API products</li>
                <li>  Talk to customers of the Armor AI API to understand their problems and design solutions to address them</li>
                <li>  Iterate rapidly to improve user and developer experience while advancing scalability, performance, observability, and security</li>
                <li>  Collaborate closely with product managers, researchers, and the rest of our engineering team to create new products around emerging research capabilities and unsolved customer needs</li> 
               </ul>
              
             <h5><span className='Subscribe1'> Your background looks something like:</span></h5>
             <ul>
            <li>  4+ years of relevant engineering experience at tech and product-driven companies</li>
            <li>  Proficiency with JavaScript, React, and other web technologies </li>
            <li> Proficiency with some backend language (we use Python) </li>
            <li>Some experience with relational databases like Postgres/MySQL</li>
            <li>Interest in AI/ML (direct experience not required) </li>
            <li>  Ability to move fast in an environment where things are sometimes loosely defined and may have competing priorities or deadlines</li>
             </ul>
             
           
            
            
            </p>
              </div>
            </div>
          </div>


          
          <div class="accordion-item mt-4" style={{borderRadius:'8px',backgroundColor:'#030614', border:'1px solid #fff',color:'#fff'}}>
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed  " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
              style={{boxShadow:'none',borderRadius:'8px',border:'none',  backgroundColor:'#030614', fontSize:'20px' }}>
              AI Safety Researcher (Remote), Dubai    
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body">
               <p> <span className='Subscribe1'> Job Description: </span> </p>
             

               <p>We are seeking a talented and highly motivated AI Safety Researcher to join our team at ArmorAi. As an AI Safety Researcher, you will play a critical role in understanding and mitigating potential risks associated with artificial intelligence systems. Your research and expertise will contribute to the development of cutting-edge strategies and technologies aimed at ensuring the safe and responsible deployment of AI.</p>


               <h5 > <span className='Subscribe1'> Responsibilities: </span></h5>

               <ul>
                <li>Conduct research on AI safety and ethics, exploring potential risks and proposing effective mitigation strategies.</li>

                <li>Collaborate with cross-functional teams to design and develop safe AI systems and algorithms.</li>

                <li>Stay up-to-date with the latest advancements in AI safety research and contribute to the wider AI safety community through publications and presentations.</li>

                <li>Evaluate and analyze existing AI models and algorithms, identifying vulnerabilities and proposing improvements.</li>

                <li>Contribute to the development of internal AI safety guidelines and policies.
                Actively participate in discussions on ethical considerations and policy implications related to AI technologies.</li>

                <li>Collaborate with engineers, ethicists, and policymakers to ensure the ethical and responsible development and deployment of AI systems.
               </li> 
               </ul>


               <h5 ><span className='Subscribe1'>  Requirements: </span></h5>

               <ul>
                <li>Ph.D. or equivalent experience in computer science, machine learning, or a related field. </li>

                <li>Strong background in AI safety research, with a focus on risk assessment and mitigation. </li>

                <li>In-depth knowledge of machine learning algorithms, deep learning, and reinforcement learning.</li>

                <li>Familiarity with the principles of ethics and their application to AI technologies.
                </li>

                <li>Proficiency in programming languages such as Python and experience with machine learning frameworks.</li>

                <li>Excellent problem-solving and critical-thinking skills, with the ability to anticipate and address potential risks.</li>

                <li>Strong communication skills, including the ability to convey complex concepts to both technical and non-technical stakeholders.</li>

                <li>Track record of publishing research papers in renowned conferences or journals related to AI safety is highly desirable.</li>


                <li>Experience working collaboratively in interdisciplinary teams is a plus.
                </li>

                <li>Join us at [ Armor Ai  ] and be at the forefront of shaping the future of AI safety. </li>

                <li>Help us build a safer and more responsible AI ecosystem that benefits humanity while minimizing potential risks.</li>
               </ul>
              </div>
            </div>
          </div>




          <div class="accordion-item mt-4" style={{borderRadius:'8px',backgroundColor:'#030614', border:'1px solid #fff',color:'#fff'}}>
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"
              style={{boxShadow:'none',borderRadius:'6px 6px 6px 6px',border:'none', backgroundColor:'#030614',  fontSize:'20px' }}>
              Senior Machine Learning Engineer (Remote), Dubai 
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p > <span className='Subscribe1'> Job Description: </span></p>


                <p>We are seeking an experienced and talented Senior Machine Learning Engineer to join our team at ArmorAI. As a Senior Machine Learning Engineer, you will lead the development and implementation of advanced machine learning models and algorithms, with a strong focus on AI safety and reliability. You will work closely with cross-functional teams to design, build, and deploy robust AI systems that adhere to the highest standards of safety and ethical considerations.</p>


                <h5  > <span className='Subscribe1'> Responsibilities: </span></h5>

                <ul>
                 <li>Lead the development and implementation of AI safety-focused machine learning models and algorithms.</li>
                 <li>Collaborate with cross-functional teams to integrate safety measures into the AI system architecture.</li>
                 <li>Conduct thorough evaluations and testing of models for risk identification and mitigation.</li>
                 <li>Stay up-to-date with advancements in machine learning and AI safety research.</li>
                 <li>Mentor junior engineers and contribute to industry thought leadership.  </li>
                </ul>

                <h5 ><span className='Subscribe1'> Requirements:</span> </h5>

                <ul>
                 <li>Master's or Ph.D. in computer science or related field. </li>
                 <li>Strong experience in building and deploying machine learning models. </li>
                 <li>Familiarity with AI safety principles and practices. </li>

                 <li>Proficiency in Python and deep learning frameworks.  </li>

                 <li>Strong problem-solving and communication skills. </li>
                 <li>Join us as a Senior Machine Learning Engineer, leading the development of AI systems with a focus on safety and reliability. Help shape the future of responsible AI at ArmorAI.</li>
                </ul>
              </div>
            </div>
          </div>



          <div class="accordion-item mt-4" style={{borderRadius:'8px',backgroundColor:'#030614', border:'1px solid #fff',color:'#fff'}}>
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed  " type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" 
              style={{boxShadow:'none',borderRadius:'6px 6px 6px 6px',border:'none', backgroundColor:'#030614',   fontSize:'20px' }}>
             <p className='m-0'> Blockchain Specialist for AI Safety Project (Remote), Dubai       </p>
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p  > <span className='Subscribe1'> Job Description:</span></p>

                <p>We are seeking a skilled Blockchain Specialist to join our AI safety project team at ArmorAI. As a Blockchain Specialist, you will play a crucial role in integrating blockchain technology into our AI safety solutions. Your expertise in blockchain and distributed ledger technologies will contribute to ensuring the transparency, security, and accountability of our AI systems.</p>

                <h5  > <span className='Subscribe1'>  Responsibilities: </span> </h5>

                <ul>
                 <li>Assess and identify opportunities to leverage blockchain technology for enhancing AI safety practices.</li>

                 <li>Design and develop blockchain-based solutions to enhance the transparency and audibility of AI algorithms and decision-making processes.</li>
                 <li>Collaborate with AI researchers, machine learning engineers, and software developers to integrate blockchain technology into our AI safety architecture.</li>
                 <li>Implement smart contracts and decentralized applications (dApps) to improve the trustworthiness and accountability of AI systems.</li>
                 <li>Research and evaluate various blockchain platforms and frameworks to determine the most suitable options for our specific AI safety needs.</li>
                 <li>Stay updated with the latest advancements and best practices in blockchain and distributed ledger technologies, and proactively apply them to enhance AI safety measures.</li>
                 <li>Collaborate with cross-functional teams to define project requirements, plan the implementation, and ensure the successful integration of blockchain technology into our AI safety project.</li>
                 <li>Assist in the identification and mitigation of potential security risks and vulnerabilities associated with the adoption of blockchain in AI systems.</li>
               
                </ul>
                       
                  <h5 > <span className='Subscribe1'>  Requirements:</span> </h5>

                <ul>
                <li>Bachelor's or Master's degree in computer science, blockchain, or a related field.
                </li>

                <li>Strong knowledge and experience in blockchain technologies, including blockchain platforms (e.g., Ethereum, Hyperledger), smart contracts, and decentralized applications (dApps).</li>

                <li>Familiarity with AI safety principles and challenges, as well as an understanding of machine learning algorithms.</li>

                <li>Proficiency in programming languages used in blockchain development, such as Solidity.</li>

                <li>Experience in designing and implementing blockchain-based solutions in real-world applications.</li>
                <li>Strong problem-solving and analytical skills, with the ability to identify and address potential risks and vulnerabilities in blockchain-integrated AI systems.</li>
                <li>Excellent communication and collaboration skills, with the ability to work effectively in cross-functional teams.</li>
                <li>Knowledge of cryptography and security principles related to blockchain technology is a plus.</li>
                <li>Join our team as a Blockchain Specialist and contribute to the advancement of AI safety through the integration of blockchain technology. Help us build secure and transparent AI systems that prioritize safety and accountability at [Company Name].</li> 
                </ul>
              </div>
            </div>
          </div>


          <div class="accordion-item mt-4" style={{borderRadius:'8px',backgroundColor:'#030614', border:'1px solid #fff',color:'#fff'}}>
            <h2 class="accordion-header" id="headingFive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" 
              style={{boxShadow:'none',borderRadius:'6px 6px 6px 6px',border:'none', backgroundColor:'#030614',   fontSize:'20px' }}>
                 Senior Project Manager (Remote), Dubai 
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p > <span className='Subscribe1'> Job Description: </span></p>

                <p>We are seeking an experienced and accomplished Senior Project Manager to join our team at ArmorAi. As a Senior Project Manager, you will oversee the planning, execution, and successful delivery of our AI safety projects. Your strong leadership, organizational skills, and technical background will ensure the seamless coordination of cross-functional teams and the achievement of project goals.</p>


                <h5 ><span className='Subscribe1'> Responsibilities:</span></h5>
                <ul>
                 <li>Lead the end-to-end management of AI safety projects, including project planning, resource allocation, and timeline management.</li>

                 <li>Define project scopes, objectives, and deliverables in collaboration with stakeholders and ensure their alignment with AI safety goals.</li>

                 <li>Develop detailed project plans, including tasks, timelines, and milestones, and ensure adherence to project schedules.</li>

                 <li>Coordinate and collaborate with cross-functional teams, including AI researchers, engineers, and stakeholders, to drive project progress and ensure effective communication.</li>

                 <li>Manage project risks, proactively identify potential issues, and implement mitigation strategies to ensure project success.</li>

                 <li>Monitor project budgets, track expenses, and report on financial performance to ensure projects are delivered within budgetary constraints.</li>

                 <li>Provide leadership and guidance to project team members, fostering a collaborative and high-performance work environment.</li>

                 <li>Conduct regular project status meetings, prepare progress reports, and effectively communicate project updates to stakeholders and senior management.</li>
                 <li>Ensure compliance with project management best practices, methodologies, and quality standards throughout the project lifecycle.</li>
                 <li>Stay informed about industry trends, emerging technologies, and AI safety practices to incorporate relevant advancements into project management approaches.</li> 
                </ul>


                <h5> <span  className='Subscribe1'> Requirements: </span></h5>

                <ul>
                 <li>Bachelor's or Master's degree in a relevant field, such as computer science, engineering, or project management.</li>

                 <li>Proven experience as a Project Manager, leading complex technical projects, preferably in the AI or technology industry.</li>
                 <li>Strong understanding of project management methodologies, tools, and best practices.
                 </li>
                 <li>Excellent organizational, time management, and leadership skills, with the ability to effectively prioritize tasks and manage competing demands.</li>
                 <li>Solid technical background, preferably in AI or related fields, enabling effective communication and coordination with technical teams.</li>
                 <li>Demonstrated ability to lead and motivate cross-functional teams, fostering collaboration and driving results.</li>
                 <li>Exceptional problem-solving and decision-making skills, with a track record of successfully managing project risks and resolving project-related challenges.</li>

                 <li>Strong communication and stakeholder management skills, with the ability to effectively communicate with technical and non-technical stakeholders.</li>
                 <li>Project management certification (e.g., PMP) is a plus. </li>
                 <li>Join us as a Senior Project Manager, and play a pivotal role in driving the successful execution of AI safety projects. Contribute to our mission of building a safer and more responsible AI ecosystem at ArmorAi.</li>
                
                </ul>
              </div>
            </div>
          </div>


          <div class="accordion-item mt-4" style={{borderRadius:'8px',backgroundColor:'#030614', border:'1px solid #fff',color:'#fff'}}>
            <h2 class="accordion-header" id="headingsix">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix" 
              style={{boxShadow:'none',borderRadius:'6px 6px 6px 6px',backgroundColor:'#030614',   border:'none', fontSize:'20px' }}>
               <p className='m-0'> Machine Learning Intern (Remote), Dubai    </p>
              </button>
            </h2>
            <div id="collapsesix" class="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p  > <span  className='Subscribe1'>Job Description: </span></p>

                <p>We are offering an exciting opportunity for talented Machine Learning Interns to join our team at ArmorAi. As a Machine Learning Intern, you will have the chance to work alongside experienced professionals and contribute to real-world AI projects. This internship will provide valuable hands-on experience in machine learning and AI safety, enabling you to enhance your skills and gain insights into cutting-edge research and industry practices.</p>


                <p> <span  className='Subscribe1'>Responsibilities: </span></p>

                <ul>
                 <li>Assist in the development and implementation of machine learning models and algorithms for AI safety applications.</li>

                 <li>Conduct data preprocessing, feature engineering, and model training under the guidance of senior machine learning engineers.</li>
                 <li>Collaborate with the team to evaluate model performance and explore techniques for model improvement.</li>
                 <li>Support AI safety research initiatives by collecting and analyzing data, and contributing to experimental design.</li>
                 <li>Assist in documenting experiments, results, and findings to contribute to project reports.</li>
                 <li>Stay updated with the latest advancements in machine learning and AI safety research and actively participate in knowledge-sharing activities.</li>
                 <li>Contribute to the team's efforts in maintaining a collaborative and innovative work environment.</li> 
                </ul>

                <ul>
                 <li>Currently pursuing a Bachelor's or Master's degree in computer science, data science, or a related field.</li>
                 <li>Strong foundation in machine learning concepts and algorithms.</li>
                 <li>Proficiency in programming languages such as Python and experience with machine learning libraries.  </li>

                 <li>Knowledge of data preprocessing, feature engineering, and model evaluation techniques.</li>
                 <li>Good analytical and problem-solving skills, with a keen attention to detail.</li>

                 <li>Strong communication and interpersonal skills, with the ability to work effectively in a team environment.</li>

                 <li>Enthusiasm for AI safety and a desire to learn and contribute to the field.</li>
                 <li>Previous coursework or projects related to machine learning or AI safety is a plus.</li>
                 
                 <li>Join our team as a Machine Learning Intern and gain practical experience in AI safety and machine learning. Develop your skills, work on exciting projects, and contribute to the advancement of responsible AI at ArmorAi.</li>
                  </ul>
              </div>
            </div>
          </div>



        </div>
          
          
          </div>
          
        </div>
        </div> 
       </div>
      </div>
     </div>
 



     <div className='container mt-5'>
      <div className='row'>
       <div className='col-lg-8 col-sm-12 m-auto'>

       <div className='row'>
        <div className='col-lg-12 col-sm-12'>
         
        <h1 className='text-center  text-white my-4 '>About <span className='Subscribe1'>ArmorAI </span></h1>
          


        <div>
         <p style={{color:'#fff', textAlign:'justify'}}><span className='Subscribe1' style={{fontSize:'18px'}}>ArmorAi</span> is an AI research and deployment company dedicated to ensuring that general-purpose artificial intelligence benefits all of humanity without any <span className='Subscribe1' style={{fontSize:'18px'}}>Risk to humans.</span> We push the boundaries of the capabilities of AI systems and seek to safely deploy them to the world through our products. AI is an extremely powerful tool that must be created with <span className='Subscribe1' style={{fontSize:'18px'}}> safety </span>and human needs at its core, and to achieve our mission, we must encompass and value the many different perspectives, voices, and experiences that form the full spectrum of humanity.  <br/> <br/>
 
         At <span className='Subscribe1'>ArmorAi,</span> we believe artificial intelligence has the potential to help people solve immense global challenges, and we want the upside of AI to be widely shared. Join us in shaping the <span className='Subscribe1'> future of technology.</span></p>


          <h4 className='text-white mt-5'> <span className=' text-rainbow-animation'> Compensation, Benefits, and Perks  </span></h4>
          <ul className='text-white' style={{lineHeight:'40px'}}>
           <li>The annual salary range for this role is $200,000 – $370,000. Total compensation also includes generous equity and benefits.</li>

           <li>Medical, dental, and vision insurance for you and your family</li>
           <li>Mental health and wellness support </li>
           <li>401(k) plan with 4% matching </li>
           <li>Unlimited time off and 18+ company holidays per year </li>
           <li>Paid parental leave (20 weeks) and family-planning support </li>
           <li>Annual learning & development stipend ($1,500 per year) </li>
           <li>We are an equal-opportunity employer and do not discriminate on the basis of race, religion, national origin, gender, sexual orientation, age, veteran status, disability or any other legally protected status.</li>
      
          </ul>
        </div>
        </div>
       </div>
       
       </div>
      </div>
     </div>




     <div className='mt-5' style={{display:Location.pathname=="/"?"none":""}}>
     <Footer/> 
     </div>


    {/* modal start */}

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{backdropFilter:'blur(10px)'}}>
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header" style={{border:'none'}}> 
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
       
    <Modal/>
    </div>
     
  </div>
</div>
</div>

     {/* modal start */}
     
    </>
  )
}

export default Career