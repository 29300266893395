import React from 'react'
import Header from '../Header/Header'
import devel from '../../Comp/img/banner.webp' 
import { useLocation } from 'react-router-dom'
import Footer from '../Footer/Footer'

const TokenSystem = () => {

  const Location = useLocation();

  return (
    <>
    <div>
    <Header/>
   </div>
 
    <div className='container gloster' style={{paddingTop:'80px'}}>
     <div className='row'>
      <div className='col-lg-8 col-md-12 col-sm-12 devel m-auto'>
            <div>
              <h1 className='text-center my-5'>
                 <span className='Token  text-rainbow-animation'>Token System </span>
                 </h1>
            </div>
      <img src={devel} alt='#'  style={{width:'100%'}}/> 
      </div>
     </div>
    </div>
    
    <div className='mt-5' style={{display:Location.pathname=="/"?"none":""}}>
    <Footer/> 
    </div>    
    </>
  )
}

export default TokenSystem